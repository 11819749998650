const FIREBASE_CONFIG = process.env.REACT_APP_FIREBASE_CONFIG;
const API_URL = process.env.REACT_APP_API_URL;
const PRODUCTION = process.env.REACT_APP_PRODUCTION;

const variables = {
    FIREBASE_CONFIG,
    API_URL,
    PRODUCTION,
  };

export default variables;